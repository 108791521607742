import { Component, OnInit, Input, Inject } from '@angular/core';
import { SeatgeekConnectionSeriesService, SeatgeekConnectionIdtranslatorService, SeriesItem } from 'ngx-seatgeek-connection';
import { StateService } from '@uirouter/angular';
import { ModalsService } from '../services/modals.service';
declare var $: any;
import * as $ from 'jquery';
import * as globals from '../globals';
import { APP_CONFIG } from "../configuration/app-configuration";
import { AppConfiguration } from "../configuration/app-configuration.model";
import { ParkingService } from '../services/parking.service';
import { ColoringService } from '../services/coloring.service';
import { Subject } from 'rxjs';
import { FlowControlService } from '../services/flow-control.service';
import { SeatgeekConnectionService } from '../services/seatgeek-connection.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-bottom-interface',
  templateUrl: './bottom-interface.component.html',
  styleUrls: ['./bottom-interface.component.css']
})
export class BottomInterfaceComponent implements OnInit {

  timerObservable = new Subject<string>();
  timer;

  get getTimer() {
    return this.timer;
  }

  get isCheckoutAvailable(): boolean {
    const discardedCount = this.discardedSeats.length;
    let addedSeatsCount;
    this.seatgeekService.addedSeatsCount$.subscribe(count => addedSeatsCount = count);
    
    return discardedCount === 0 ? addedSeatsCount > 0 : addedSeatsCount >= discardedCount;
  }

  isRelocationAvailable() {
    if (this.flowControl.appointmentTime) {
      const appointmentTimestamp = new Date(this.flowControl.appointmentTime).getTime();
      // const appointmentTimestamp = 1598959488684;
      const now = new Date().getTime();
      return (now >= appointmentTimestamp) ? true : false;
    }
    return true;
  }

  constructor(private stateService: StateService,
    private connection: SeatgeekConnectionSeriesService,
    private modals: ModalsService,
    private parking: ParkingService,
    private coloring: ColoringService,
    public flowControl: FlowControlService,
    private seatgeekService: SeatgeekConnectionService,
    @Inject(APP_CONFIG) public app_config: AppConfiguration) {}

  @Input() isSelectionAllowed;
  @Input() discardedSeats: Object[];
  @Input() selectedSeats: Object[];

  public isTicketSelectedVisible() {
    // return this.state.blockmap.selected || this.state.seatmap.selected.length > 0;
    return false;
  }

  private discardNonSelectedSeats() {
    let basket;
    this.connection.Basket.subscribe(
       basketResponse => {
         basket = basketResponse;
       },
       error => {
         console.log(error);
       }
    );
    const discardedSeatsIndexes = Object.keys(this.discardedSeats);
    let count = discardedSeatsIndexes.length;
     for (let i = 0; i < basket.length; ++i) {
       if (discardedSeatsIndexes.indexOf(basket[i]['id']) < 0) {
         this.connection.discardBasketItems(basket[i]['id']).subscribe(
           discardBasketItemsResponse => {
            //  console.log(discardBasketItemsResponse);
           },
           error => {
             console.log(error);
           }
         );
       } else {
         count--;
       }
       if (count === 0) {
        //  this.state.blockmap.showLoader = true;
        //  this.state.seatmap.showLoader = true;
        //  this.state.view3d.showLoader = true;
         this.goToRelocateStep(this.discardedSeats);
       }
     }
  }

  private goToRelocateStep(toSend) {
    this.stateService.go('common.seatselection.landing', {data: toSend});
  }

  public relocateView() {
    if (Object.keys(this.discardedSeats).length > 0) {
        // if (this.state.blockmap.size === 'large') {
        //   this.state.blockmap.showLoader = true;
        // } else if (this.state.seatmap.size === 'large') {
        //   this.state.seatmap.showLoader = true;
        // } else if (this.state.view3d.size === 'large') {
        //   this.state.view3d.showLoader = true;
        // }
        this.discardNonSelectedSeats();
    } else {
       if (this.app_config.addonsConfiguration.addonsMaxSelection !== 0) {
         this.modals.justAddonsModal();
       } else {
         this.modals.noAddonsModal();
       }
    }
  }

  public checkoutModal() {
    let error;
    this.connection.Basket.pipe(take(1)).subscribe(
       (basketResponse: SeriesItem[]) => {

        // Check if there has been any downgrades not chatched earlier
        if (this.app_config.preventDowngrades) {
          const updates = basketResponse.filter(item => item.action === "Update" && item.insertionMode === "Return");
  
          if (updates.length) {
            const hasDowngrades = !updates.every(returned => {
                const substitute = basketResponse.find(item => item.id === returned.SubstitutedForItemId);
                return substitute && substitute.price >= returned.price;
              });

            if (hasDowngrades) {
              error = "downgrades";
            }
          }
        }


        // Check if user tries to proceed  with more seats than the max
        let newSeatsCount;
        this.seatgeekService.newSeatsCount$.subscribe(count => newSeatsCount = count);
        if ((newSeatsCount > this.app_config.seatsMaxSelection) && !error) {
          error = "maxselection"
          return;
        }


         this.parking.parkingSlots = [];
         let count = 0;
         for (let j = 0; j < basketResponse.length; ++j) {
           if (basketResponse[j]['insertionMode'] === 'Add') {
             count++;
           }
         }
         for (let i = 1; i <= count / 2; i++) {
             this.parking.parkingSlots.push(i);
          }
       },
       error => {
         console.log(error);
       }
    );

    if (error === "downgrades") {
      this.modals.downgradeCheckoutModal();
    } else if (error === "maxselection") {
      this.modals.maxSelectionCheckoutModal();
    } else if (this.app_config.parkingConfiguration.isParkingAvailable && this.parking.parkingGeneralAvailability.length) {
      this.modals.parkingCheckoutModal();
    } else {
      this.modals.checkoutModal();
    }
  }

  time2string(delta) {
    // Days
    const days = Math.floor(delta / 86400);
    delta -= days * 86400;
    // Hours
    const hoursNum = Math.floor(delta / 3600) % 24;
    const hours = (hoursNum < 10 ? '0' : '') + hoursNum;
    delta -= hoursNum * 3600;
    // Minutes
    const minutesNum = Math.floor(delta / 60) % 60;
    const minutes = (minutesNum < 10 ? '0' : '') + minutesNum;
    delta -= minutesNum * 60;
    // Seconds
    const secondsNum = Math.floor(delta % 60);
    const seconds = (secondsNum < 10 ? '0' : '') + secondsNum;
    return days + 'd ' + hours + ':' + minutes + ':' + seconds;
  }

  setTimer() {
    if (this.flowControl.appointmentTime) {
      const appointmentTimestamp = new Date(this.flowControl.appointmentTime).getTime();
      // const appointmentTimestamp = 1598959488684;
      const now = new Date().getTime();
      const diff = Math.abs(appointmentTimestamp - now) / 1000;
      // console.log(diff);
      const timeString = this.time2string(diff);
      this.timerObservable.next(timeString);
    }
  }

  setTimerInterval() {
    setInterval(() => {
      this.setTimer();
    }, 1000);
  }

  ngOnInit() {
    this.timerObservable.subscribe(
      timer => {
        this.timer = timer;
      }
    );
    this.setTimer();
    this.setTimerInterval();
    // GET PARKING GENERAL AVAILABILITY
    if(this.app_config.parkingConfiguration.isParkingAvailable){
      this.connection.getParkingGeneralAvailability(this.app_config).subscribe(
          parkingAvailability => {
              this.parking.parkingGeneralAvailability = parkingAvailability;
          },
          error => {
              console.log(error);
          }
      );
    }
  }

  // tslint:disable-next-line:use-life-cycle-interface
  ngAfterViewChecked() {
    // if (this.state.blockmap.size !== 'large') {
    //   this.state.blockmap.moduleRef['updateSize']();
    // }
    // this.coloring.applyInterfaceColors();
  }

}
