
import { InjectionToken } from '@angular/core';
import { AppConfiguration } from "./app-configuration.model"

export const APP_CONFIG = new InjectionToken<AppConfiguration>('AppConfiguration');
export const ApplicationConfiguration: AppConfiguration = {
  applicationType: 'Relocation',
  generalAdmissionAreas: [],
  lockedAreas: [],
  whitelistSections: null,
  blacklistSections: null,
  defaultPricetype: "",
  includeSearch: true,
  filtersConfiguration: {
    includeFilters: false
  },
  redirect: {
    isRedirectEnabled: true,
    redirectURL: "",
  },
  addonsConfiguration: {
    isAddonsAvailable: false,
    addonsMaxSelection: 0
  },
  parkingConfiguration: {
    isParkingAvailable: false,
    parkingSeriesID: null,
    parkingPricetype: null,
    parkingTranslation: {
    },
    parkingMaxSelection: 2 //?
  },
  popoversConfiguration: {
    includeDescription: true,
    includeSeatsCount: true,
    includePricing: true
  },
  seatsMaxSelection: 6, //?
  dataProvider: "",
  preventIsolatedSeats: true,
  isAreaMap: false,
  colors: {
    mapElement: {
      available: "",
      selected: "",
      pending: ""
    },
    buttons: "",
    buttonsHover: "",
    popoverText: "",
    topbar: "",
    bottomInterface: "",
    subInterface: {
      header: "",
      listTitle: ""
    },
    tickets: {
      text: "",
      actions: "",
      actionsHover: ""
    },
  },
  preventDowngrades: false
};