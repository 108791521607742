import { Component, OnInit } from '@angular/core';
import { SeatgeekConnectionSeriesService } from 'ngx-seatgeek-connection';
import { LoaderService } from './services/loader.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  // get status(): GeneralState {
  //   return this.tk3d.tk3dStatus;
  // }

  constructor(private connection: SeatgeekConnectionSeriesService,
    public loaderService: LoaderService)  {

  }

  ngOnInit() {
    const token = this.getQueryVariable('t');
    this.connection.init(token);
  }

  getQueryVariable(variable) {
      const query = window.location.search.substring(1);
      const vars = query.split('&');
      for (let i = 0; i < vars.length; i++) {
        const pair = vars[i].split('=');
        if (pair[0] == variable) {
          return pair[1];
        }
      }
      return('noToken');
  }
}
