import { Injectable, Inject } from '@angular/core';
declare var $: any;
import * as $ from 'jquery';
import { APP_CONFIG } from "../configuration/app-configuration";
import { AppConfiguration } from "../configuration/app-configuration.model";

@Injectable({
    providedIn: 'root'
})

/* MODALS HANDLING */

export class ModalsService {

  public currentGeneralAdmissionArea: any;
  private generalAdmissionAreas: Object = {};

  constructor(@Inject(APP_CONFIG) private app_config: AppConfiguration) {
    app_config.generalAdmissionAreas.forEach(element => {
      this.generalAdmissionAreas[element] = true;
    });
  }

  // MODALS
  public Modal(title: string, text: any) {
      $('.modal-title').text(title);
      $('.api-error-txt').text(text);
      $('#APIerror').modal();
  }

  public throwErrorModal(errorMessage: string, text = null) {
    let errorText: string;
    if (text != null) {
      errorText = text;
    } else {
      if (errorMessage === 's:ContextRequired') {
          errorText = 'Your session has expired. Please, try refreshing the page.';
      } else {
          errorText = 'An error happened. Please, try again later.';
      }
    }
    this.Modal('Oops!', errorText);
  }

  public getGeneralAdmissionAreas() {
    return this.generalAdmissionAreas;
  }

  public generalAdmissionModal() {
      $('#generalAdmissionModal').modal();
  }

  public checkoutModal() {
      $('#checkoutModal').modal();
  }

  public infoModal() {
    $('#infoModal').modal();
  }

  public justAddonsModal() {
    $('#justAddonsModal').modal();
  }

  public parkingCheckoutModal() {
    $('#parkingCheckoutModal').modal();
  }

  public selectPricetypeModal() {
    $('#selectPricetypeModal').modal();
  }

  public noAddonsModal() {
    $('#noAddonsModal').modal();
  }

  public noDowngradesModal() {
    $('#noDowngradesModal').modal();
  }


  public downgradeCheckoutModal() {
    $('#downgradeCheckoutModal').modal();
  }

  public maxSelectionCheckoutModal() {
    $('#maxSelectionCheckoutModal').modal();
  }

  public noaddonsCheckoutModal() {
    $('#maxSelectionAddonsModal').modal();
  }

}
