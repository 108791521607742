import { Component, OnInit, Input, Inject } from '@angular/core';
import { SeatgeekConnectionIdtranslatorService } from 'ngx-seatgeek-connection';
import * as globals from '../globals';
declare var $: any;
import * as $ from 'jquery';
import { APP_CONFIG } from "../configuration/app-configuration"
import { AppConfiguration } from "../configuration/app-configuration.model"
import { DVM_CONFIG } from "../configuration/dvm-configuration";
import { DVMConfiguration } from "../configuration/dvm-configuration.model";
import { DVMService } from '../services/dvm.service';
import { FlowControlService } from '../services/flow-control.service';
import { Popover3dviewService } from '../services/popover-3dview.service';


@Component({
  selector: 'app-main-interface',
  templateUrl: './main-interface.component.html',
  styleUrls: ['./main-interface.component.css']
})
export class MainInterfaceComponent implements OnInit {

  @Input() selectedSeats: object[];
  @Input() isSelectionAllowed;
  _previewPlan: string;
  globals = globals;

  get isView3DOpen() {
    if (!this.dvmService.viewer3d || !this.dvmService.viewer3d.getViewId()) {
      return false;
    }
    return true;
  }

  get subInterfacePlacement() {
    if (globals.isMobile()) {
      return 'top';
    }
    return 'left';
  }

  get previewPlan(): string {
    return this._previewPlan;
  }

  get hideClubPreviews() {
    if (this.dvmService.viewer) {
      return this.isView3DOpen || this.dvmService.viewer.getMapId() !== this.dvmConfig.map_id ||
      globals.isMobile() || !this.dvmService.viewer.getMapId();
    }
    return null;
  }

  get isBlockmapOpen() {
    if (this.dvmService.viewer && this.dvmService.viewer.getMapId()) {
      return this.dvmService.viewer.getMapId() === this.dvmConfig.map_id;
    }
    return true;
  }

  constructor(private translator: SeatgeekConnectionIdtranslatorService,
              @Inject(APP_CONFIG) public dvConfig: AppConfiguration,
              @Inject(DVM_CONFIG) public dvmConfig: DVMConfiguration,
              public popover3dview: Popover3dviewService,
              public dvmService: DVMService,
              // private userService: UserService,
              public flowControl: FlowControlService) {
  }

  popoverMouseEnter() {
    this.popover3dview.deleteTimer();
  }

  popoverMouseLeave() {
    this.popover3dview.setTimer();
  }

  open3DView(viewId) {
    this.dvmService.load3DView(viewId);
    const tooltip: HTMLElement = document.querySelector('#tooltip');
    tooltip.removeAttribute('data-show');
  }

  ngOnInit() {
    this.translator.sectionsInAreas.subscribe(
      data => {
        // console.log(data);
        // Al cambiar los datos desde aquí, se edita para todas las suscripciones futuras.
      }
    );
    this.translator.translationsHolder.subscribe(
      data => {
        // console.log(data);
        // Al cambiar los datos desde aquí, se edita para todas las suscripciones futuras.
      }
    );
  }

  ngAfterViewChecked() {
    if (!globals.isMobile() && window.innerWidth >= 1200) {
      // document.getElementById('main-interface').style.height = (document.getElementById('main-interface').offsetWidth / 1.764) + 'px';
      // document.getElementById('interface-wrap').style.width = (document.getElementById('main-interface').offsetHeight * 1.764) + 'px';

      document.getElementById('main-interface').style.height = window.innerHeight -
    ($('#topbar').height() + $('#bottom-interface').height()) + 'px';
    } else {
      document.getElementById('main-interface').style.height = window.innerHeight / 2 + 'px';
    }
    // document.getElementById('main-interface').style.height = window.innerHeight -
    // ($('#topbar').height() + $('#bottom-interface').height()) + 'px';
  }
}
