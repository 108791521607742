import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MainInterfaceComponent } from '../main-interface/main-interface.component';
import { SubInterfaceComponent } from '../sub-interface/sub-interface.component';
import { TicketComponent } from '../ticket/ticket.component';
import { TopbarComponent } from '../topbar/topbar.component';
import { BottomInterfaceComponent } from '../bottom-interface/bottom-interface.component';
import { ModalsComponent } from '../modals/modals.component';
import { FormsModule } from '@angular/forms';
import { SearchComponent } from '../search/search.component';
import { FiltersComponent } from '../filters/filters.component';

@NgModule({
  declarations: [
    MainInterfaceComponent,
    SubInterfaceComponent,
    TicketComponent,
    TopbarComponent,
    BottomInterfaceComponent,
    // AreamapBindedComponent,
    // AreamapMiniComponent,
    ModalsComponent,
    // SearchComponent,
    // FiltersComponent
  ],
  exports: [
    MainInterfaceComponent,
    SubInterfaceComponent,
    TicketComponent,
    TopbarComponent,
    BottomInterfaceComponent,
    // AreamapBindedComponent,
    // AreamapMiniComponent,
    ModalsComponent,
    // SearchComponent,
    // FiltersComponent,
    // Tk3dModule,
    FormsModule
  ],
  imports: [
    CommonModule,
    // Tk3dModule,
    FormsModule
  ]
})
export class SharedModule { }
