import { LazyLoadResult, Ng2StateDeclaration } from '@uirouter/angular';
import { AppCommonComponent } from '../app-common';

export const states: Ng2StateDeclaration[] = [
  {
    name: 'common',
    abstract: true,
    component: AppCommonComponent
  },
];
