
import { InjectionToken } from "@angular/core";
import { SeatgeekConnectionConfiguration } from "ngx-seatgeek-connection";
import { SeatGeekConfiguration } from "./seatgeek-configuration.model";

export const SEATGEEK_CONFIG = new InjectionToken<SeatgeekConnectionConfiguration>('SeatGeek Configuration');
export const SeatGeekConfig: SeatgeekConnectionConfiguration = {
    seatgeekClientUrl: null,
    client: "",
    parkingID: "",
    proxyApiUrl: "",
    areaPricesMultipleSectors: true,
    idTranslationFromFiles: true,
    dataConfig: {
      baseUrl: "",
      paths: {
        areaMapping: "",
        sectionsInAreas: "",
        areaNames: "",
        seats: ""
      },
      local: true
    }
};
