import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DescriptionsService {

  descriptions: any;
  popoverHeaders: any;

  constructor() { }


  getDescriptionOf(node) {
    return this.descriptions[node.id];
  }

  getHeaderOf(node) {
    return this.popoverHeaders[node.id];
  }
}
