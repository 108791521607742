import { LazyLoadResult, Ng2StateDeclaration, Transition } from '@uirouter/angular';
import { SeatSelectionComponent } from '../seatselection.component';
import { SeatSelectionLandingComponent } from '../seatselection-landing';
import { ApplicationConfiguration } from 'src/app/app.configuration';

export const states: Ng2StateDeclaration[] = [
  {
    name: 'common.seatselection',
    url: '/seatselection/',
    abstract: true,
    component: SeatSelectionComponent
  },
  {
    name: 'common.seatselection.landing',
    url: '?t',
    component: SeatSelectionLandingComponent,
    redirectTo: getState(),
    data: {isSelectionAllowed: true},
    params: {
       data: null
     },
    resolve: [
      {
        token: 'token',
        deps: [Transition],
        resolveFn: (trans) => {
          return trans.params().token;
        }
      },
      {
         token: 'data',
         deps: [Transition],
         resolveFn: (trans) => {
           return trans.params().data;
         }
      }
    ]
  }
];

function getState() {
  switch (ApplicationConfiguration.applicationType) {
    case 'Relocation':
      return null;
      break;
    case 'NewSales':
      return null;
      break;
    default:
      return 'common.preview.landing';
      break;
  }
}
