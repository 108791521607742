import { Component, OnInit } from '@angular/core';
import { ApplicationConfiguration } from '../app.configuration';
import { UIRouter } from '@uirouter/angular';

@Component({
  selector: 'app-preview',
  templateUrl: './preview.component.html',
})
export class PreviewComponent implements OnInit {

  constructor(private router: UIRouter) { }

  ngOnInit() {
    switch (ApplicationConfiguration.applicationType) {
      case 'Relocation':
        break;
      case 'NewSales':
        return 'common.seatselection.landing';
        break;
      default:
        return 'common.preview.landing';
        break;
    }
  }
}
