import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { AsyncSubject, forkJoin, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { MODALS_CONFIG } from '../configuration/modals-configuration';
import { ModalsConfiguration } from '../configuration/modals-configuration.model';
import { PriceTypeService } from "../configuration/standard-services/price-type.service";

import { AppConfiguration } from "../configuration/app-configuration.model";
import { DVM_CONFIG } from "../configuration/dvm-configuration";
import { SEATGEEK_CONNECTION_CONFIG, SeatgeekConnectionConfiguration } from 'ngx-seatgeek-connection';

// Will be removed and fetched via HTTP in a future version
import { APP_CONFIG } from '../configuration/app-configuration';
import { DVMConfiguration } from '../configuration/dvm-configuration.model';
import { DescriptionsService } from './descriptions.service';
import { FlowControlService } from './flow-control.service';


import * as configurationJSON from "../configuration/configuration.json";

@Injectable({
  providedIn: 'root'
})
export class FetcherService {

  private dataSemaphore$ = new AsyncSubject<void>();

  get onDataFetched$() {
    return this.dataSemaphore$.asObservable();
  }

  constructor(private http: HttpClient, private priceTypeService: PriceTypeService,
    @Inject(APP_CONFIG) private appConfig: AppConfiguration,
    @Inject(DVM_CONFIG) private dvmConfig: DVMConfiguration,
    @Inject(SEATGEEK_CONNECTION_CONFIG) private seatgeekConfig: SeatgeekConnectionConfiguration,
    @Inject(MODALS_CONFIG) private modalsConfig: ModalsConfiguration,
    private descriptionsService: DescriptionsService) { }



  // Receives the client ID and fetch all its associated resources into the services
  fetchData(client) {


    const catcher = (fileName, logType) => catchError((_) => {
      console[logType](`${fileName} is not available for this client`);
      return of(null)
    })


      const SeasonTicketAutoNew$ = this.http.get(`/assets/seatgeekdataconfig/titans/SeasonTicketAutoNew.json`)
                            .pipe(catcher("Pricing file", "error"));
      
      const SeasonTicketAutoRenewal$ = this.http.get(`/assets/seatgeekdataconfig/titans/SeasonTicketAutoRenewal.json`)   
                            .pipe(catcher("Pricing file", "error"));  

      const SeasonTicketAutoUpgrade$ = this.http.get(`/assets/seatgeekdataconfig/titans/SeasonTicketAutoUpgrade.json`)
                            .pipe(catcher("Pricing file", "error"));

      const SeasonTicketEarlyNew$ = this.http.get(`/assets/seatgeekdataconfig/titans/SeasonTicketEarlyNew.json`)
                            .pipe(catcher("Pricing file", "error"));

      const SeasonTicketEarlyNewTaxFree$ = this.http.get(`/assets/seatgeekdataconfig/titans/SeasonTicketEarlyNewTaxFree.json`)
                            .pipe(catcher("Pricing file", "error"));

      const SeasonTicketEarlyRenewal$ = this.http.get(`/assets/seatgeekdataconfig/titans/SeasonTicketEarlyRenewal.json`)
                            .pipe(catcher("Pricing file", "error"));
      
      const SeasonTicketEarlyRenewalTaxFree$ = this.http.get(`/assets/seatgeekdataconfig/titans/SeasonTicketEarlyRenewalTaxFree.json`)
                            .pipe(catcher("Pricing file", "error"));

      const SeasonTicketEarlyUpgrade$ = this.http.get(`/assets/seatgeekdataconfig/titans/SeasonTicketEarlyUpgrade.json`)
                            .pipe(catcher("Pricing file", "error"));

      const SeasonTicketEarlyUpgradeTaxFree$ = this.http.get(`/assets/seatgeekdataconfig/titans/SeasonTicketEarlyUpgradeTaxFree.json`)
                            .pipe(catcher("Pricing file", "error"));
              
      const SeasonTicketRenewal = this.http.get(`/assets/seatgeekdataconfig/titans/SeasonTicketRenewal.json`)
                            .pipe(catcher("Pricing file", "error"));                              
      

      const seatPriceTypeJSON$ = this.http.get(`/assets/seatgeekdataconfig/seat-priceType.json`)
                            .pipe(catcher("PriceType per seat file", "log"));

      // const customPriceTypesJSON$ = this.http.get(`/assets/seatgeekdataconfig/customPriceTypes.json`)
      //                       .pipe(catcher("Custom PriceTypes file", "log"));

      const descriptionsJSON$ = this.http.get(`/assets/seatgeekdataconfig/descriptions.json`)
                            .pipe(catcher("Area descriptions file", "log"));

      const popoverHeadersJSON$ = this.http.get(`/assets/seatgeekdataconfig/popover-headers.json`)
                            .pipe(catcher("Area descriptions file", "log"));




      forkJoin([seatPriceTypeJSON$, descriptionsJSON$, popoverHeadersJSON$, SeasonTicketAutoNew$,SeasonTicketAutoRenewal$,SeasonTicketAutoUpgrade$, SeasonTicketEarlyNew$, SeasonTicketEarlyNewTaxFree$, SeasonTicketEarlyRenewal$, SeasonTicketEarlyRenewalTaxFree$, SeasonTicketEarlyUpgrade$, SeasonTicketEarlyUpgradeTaxFree$, SeasonTicketRenewal])
        .subscribe(([seatPriceType, descriptions, popoverHeaders, SeasonTicketAutoNew, SeasonTicketAutoRenewal, SeasonTicketAutoUpgrade, SeasonTicketEarlyNew, SeasonTicketEarlyNewTaxFree, SeasonTicketEarlyRenewal, SeasonTicketEarlyRenewalTaxFree, SeasonTicketEarlyUpgrade, SeasonTicketEarlyUpgradeTaxFree, SeasonTicketRenewal]) => {

          const configuration = configurationJSON["default"][client];

          for (const [key, value] of Object.entries(configuration["app-configuration"])) {
            this.appConfig[key] = value;
          }
          for (const [key, value] of Object.entries(configuration["dvm-configuration"])) {
            this.dvmConfig[key] = value;
          }
          for (const [key, value] of Object.entries(configuration["seatgeek-configuration"])) {
            this.seatgeekConfig[key] = value;
          }

          const priceMap = this.priceTypeService.priceDictionary;

          priceMap.set("Season Ticket Auto New", SeasonTicketAutoNew);
          priceMap.set("Season Ticket Auto Renewal", SeasonTicketAutoRenewal);
          priceMap.set("Season Ticket Auto Upgrade", SeasonTicketAutoUpgrade);
          priceMap.set("Season Ticket Early New", SeasonTicketEarlyNew);
          priceMap.set("Season Ticket Early New Tax Free", SeasonTicketEarlyNewTaxFree);
          priceMap.set("Season Ticket Early Renewal", SeasonTicketEarlyRenewal);
          priceMap.set("Season Ticket Early Renewal Tax Free", SeasonTicketEarlyRenewalTaxFree);
          priceMap.set("Season Ticket Early Upgrade", SeasonTicketEarlyUpgrade);
          priceMap.set("Season Ticket Early Upgrade Tax Free", SeasonTicketEarlyUpgradeTaxFree);
          priceMap.set("Season Ticket Renewal", SeasonTicketRenewal);
          priceMap.set("default", SeasonTicketEarlyRenewal)

          console.log(this.priceTypeService.priceDictionary)
          this.priceTypeService.priceTypesBySeat = seatPriceType || undefined;
          // this.priceTypeService.customPriceTypes = customPriceTypes || undefined;
          this.descriptionsService.descriptions = descriptions || { };
          this.descriptionsService.popoverHeaders = popoverHeaders || { }

          this.dataSemaphore$.next();
          this.dataSemaphore$.complete();
        });
  }
}
