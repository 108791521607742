import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { SeatgeekConnectionSeriesService, SeriesItem } from 'ngx-seatgeek-connection';
import { AsyncSubject, forkJoin, of, Subject } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { APP_CONFIG } from '../app-configuration';
import { AppConfiguration } from '../app-configuration.model';

@Injectable({
  providedIn: 'root'
})
export class PriceTypeService {
  public seatpricingSeasonEmployee;
  public seatpricingCompEmployee;
  
  public seatpricing;
  public areapricing;

  public priceTypesBySeat;

  public hasMultiplePriceTypes = false;
  public multiplePriceTypes;

  public pricetypesList;
  public customPriceTypes;

  public priceDictionary = new Map<string, any>();
  public selectedPriceType: string;
  public price: any;


  public overridePriceType;
  // public defaultPriceType; // Llenar desde el init si se quiere tener aqui


  constructor(private http: HttpClient,
    @Inject(APP_CONFIG) private appConfig: AppConfiguration) {
  }


  getPriceType(nodeId: string) {

    let priceType;
    
    if (this.hasMultiplePriceTypes && this.multiplePriceTypes[nodeId]) {
      priceType = null;
    } else if (this.customPriceTypes && this.customPriceTypes.seats[nodeId]) {
      priceType = this.customPriceTypes.seats[nodeId];
    } else if (this.customPriceTypes && this.customPriceTypes.sections[nodeId.split("-")[0]]) {
      priceType = this.customPriceTypes.sections[nodeId.split("-")[0]];
    } else if (this.overridePriceType) {
      priceType = this.overridePriceType;
    } else {
      priceType = this.appConfig.defaultPricetype;
      if (!priceType) {
        throw new Error("No pricetype available");
      }
    }
    return priceType;
  }

  setPriceType(priceType:string): void {
    // debugger;

    if(this.priceDictionary.has(priceType)){
      this.price = this.priceDictionary.get(priceType);
      this.selectedPriceType = priceType;
    }else{
      this.price = this.priceDictionary.get(this.appConfig.defaultPricetype);
      this.selectedPriceType = this.appConfig.defaultPricetype;
    }

    // console.log("Price Type Seleccionado: ", this.selectedPriceType);
  }

  TitansGetPriceKey(priceName: string, relocation?: boolean): string {

    switch(priceName){
      case "Season Employee Purchase":
        return "e6d4ce02-6e61-ee11-8445-bc4e14c94833";
      case "Comp Employee":
        return "a92d1579-6559-ee11-8445-d1b58d2036ee";
        //2024 - 2025
      case 'Ticket Auto Renewal':
        return "e26793d5-2666-ef11-84bd-82d9585ac74f"
      case 'Season Ticket Early Renewal':
        return "e2879d12-beab-ed11-83d0-8f669fa8197c"
      case 'Season Ticket Renewal':
        return "683d5c0b-beab-ed11-83d0-8f669fa8197c"
    }

  }
}
