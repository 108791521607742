import { NgModule } from '@angular/core';
import { UIRouterModule } from '@uirouter/angular';

import { config } from './app-routing.config';
import { PreviewModule } from '../preview/preview.module';
import { SeatSelectionModule } from '../seatselection/seatselection.module';


@NgModule({
  imports: [
    UIRouterModule.forRoot(config),
    PreviewModule,
    SeatSelectionModule
  ],
  exports: [UIRouterModule, PreviewModule, SeatSelectionModule]
})
export class AppRoutingModule { }
