import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RootModule, UIRouterModule } from '@uirouter/angular';

import { PreviewLandingComponent } from './preview-landing';
import { PreviewComponent } from './preview.component';
import { SeatgeekConnectionModule, SeatgeekConnectionConfiguration } from 'ngx-seatgeek-connection';
import { states, config } from './preview-routing';
import { SeatGeekConfig } from '../configuration/seatgeek-configuration';
import { SharedModule } from '../shared/shared.module';
// import { SeatgeekConfiguration } from '../app.configuration';



@NgModule({
  imports: [
    CommonModule,
    UIRouterModule,
    SharedModule,
    UIRouterModule.forChild(config),
    // SeatgeekConnectionModule.forRoot(SeatGeekConfig)
  ],
  declarations: [PreviewComponent, PreviewLandingComponent]
})
export class PreviewModule { }
