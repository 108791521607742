import { Ng2StateDeclaration, Transition } from '@uirouter/angular';
import { PreviewComponent } from '../preview.component';
import { PreviewLandingComponent } from '../preview-landing/';
import { ApplicationConfiguration } from 'src/app/app.configuration';

export const states: Ng2StateDeclaration[] = [
  {
    name: 'common.preview',
    url: '/preview/',
    abstract: true,
    component: PreviewComponent
  },
  {
    name: 'common.preview.landing',
    url: '?t',
    component: PreviewLandingComponent,
    data: {isSelectionAllowed: false},
    redirectTo: getState(),
    resolve: [
      {
        token: 'token',
        deps: [Transition],
        resolveFn: (trans) => {
          return trans.params().t;
        }
      }
    ]
  }
];

function getState() {
  switch (ApplicationConfiguration.applicationType) {
    case 'Relocation':
      return null;
      break;
    case 'NewSales':
      return 'common.seatselection.landing';
      break;
    default:
      return null;
      break;
  }
}
