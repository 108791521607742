import { Injectable, Inject } from '@angular/core';
declare var $: any;
import * as $ from 'jquery';
import { SeatgeekConnectionSeriesService } from 'ngx-seatgeek-connection';
import { ModalsService } from './modals.service';
import { APP_CONFIG } from "../configuration/app-configuration";
import { AppConfiguration } from "../configuration/app-configuration.model";

@Injectable({
    providedIn: 'root'
})

export class ParkingService {

    public parkingGeneralAvailability;
    public parkingSlots = Array.from(Array(this.config.parkingConfiguration.parkingMaxSelection), (e, i) => i + 1);
    nrSelect = false;
    public parkingSelectFlag = true;

    constructor(private connection: SeatgeekConnectionSeriesService,
        private modals: ModalsService,
        @Inject(APP_CONFIG) private config: AppConfiguration) {
            if(this.config.parkingConfiguration.isParkingAvailable){
                console.log(this.config);
                this.connection.getParkingGeneralAvailability(this.config).subscribe(
                    parkingAvailability => {
                        this.parkingGeneralAvailability = parkingAvailability;
                        console.log(this.parkingGeneralAvailability);
                    },
                    error => {
                        console.log(error);
                    }
                );
            }
    }

    /* PRIVATE */

    private checkoutPostMessage() {
        window.parent.postMessage(JSON.stringify({'EventName': 'checkout'}), '*');
        console.log('Checkout');
    }

    /* PUBLIC */

    public parkingSelect1(value: string) {
        if (value != null) {
            if (value === 'true') {
            this.parkingSelectFlag = false;
            } else if (value === 'false') {
            this.parkingSelectFlag = true;
            }
        }
    }

    public addParking(areaid: string, slots: number) {
        console.log(areaid, slots);
        this.connection.getPriceTypes().subscribe(
            priceTypes => {
                const pT = priceTypes[0]['b:key'];
                let count = slots;
                for (let i = 0; i < slots; ++i) {
                    this.connection.addGAParkingToBasket(areaid, this.config).subscribe(
                        addGAParkingResponse => {
                            console.log(addGAParkingResponse);
                            count--;
                            if (count === 0) {
                            this.checkoutPostMessage();
                            }
                        },
                        error => {
                            console.log(error);
                        }
                    );
                }
            }
        );
    }

}
