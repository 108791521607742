import { SeatgeekConnectionConfiguration } from 'ngx-seatgeek-connection';
import { environment } from '../environments/environment';
import { variables } from './variables';
import { AppConfiguration } from './app-configuration.model';
import { DVMConfiguration } from './dvm-configuration.model';
import { InjectionToken } from '@angular/core';

/*
*
* Application Configuration
*
*/
export const APP_CONFIG = new InjectionToken<AppConfiguration>('AppConfiguration');
export const ApplicationConfiguration: AppConfiguration = {
  applicationType: 'Relocation',
  generalAdmissionAreas: [],
  lockedAreas: [],
  defaultPricetype: '',
  includeSearch: true,
  filtersConfiguration: {
    includeFilters: false
  },
  redirect: {
    isRedirectEnabled: true,
    redirectURL: ''
  },
  addonsConfiguration: {
    isAddonsAvailable: false,
    addonsMaxSelection: 0
  },
  parkingConfiguration: {
    isParkingAvailable: false,
    parkingSeriesID: null,
    parkingPricetype: null,
    parkingTranslation: {
    },
    parkingMaxSelection: 2
  },
  popoversConfiguration: {
    includeDescription: true,
    includeSeatsCount: true,
    includePricing: true
  },
  seatsMaxSelection: 6,
  dataProvider: 'SeatGeek',
  preventIsolatedSeats: true,
  isAreaMap: false,
  colors: {
    mapElement: {
      available: '#1D8EF9',
      selected: 'white',
      pending: 'orange'
    },
    buttons: '#1D8EF9',
    buttonsHover: '#00db4f',
    popoverText: '#1D8EF9',
    topbar: '#231F20',
    bottomInterface: '#000000',
    subInterface: {
      header: '#231F20',
      listTitle: '#1D8EF9'
    },
    tickets: {
      text: '#231F20',
      actions: '#231F20',
      actionsHover: '#1D8EF9'
    }
  },
  preventDowngrades: false
};

/*
*
* DVM Configuration
*
*/
export const DVM_CONFIG = new InjectionToken<DVMConfiguration>('DVM Configuration');
export const DVMConfig: DVMConfiguration = {
  "venue_id": "nam-us-00098-fccincinnati",
  "map_id": "blockmap",
  "container": "viewer-container",
  "version": "development"
};
/*
*
* Connection Configuration
*
*/
// export const SeatgeekConfiguration: SeatgeekConnectionConfiguration = {
//   seatgeekClientUrl: null,
//   client: 'cincinnati',
//   parkingID: '38abe55b-73aa-e911-a9cd-92c5700871b7',
//   proxyApiUrl: environment.proxyApiUrl,
//   areaPricesMultipleSectors: true, // Not being used
//   idTranslationFromFiles: true,
//   dataConfig: {
//     baseUrl: 'assets/seatgeekdataconfig/',
//     paths: {
//       areaMapping: 'areamapping.json',
//       sectionsInAreas: 'sectionsinareas.json',
//       areaNames: 'areanames.json',
//       seats: 'seats'
//       // areaDescriptions: 'areadesc.json', // test with different file input format
//       // areaDescriptions: 'areadesc_fix.json',
//     },
//     local: true
//   }
// };
