import { Injectable, Inject } from '@angular/core';
import { DVMService } from '../services/dvm.service';

import { DVM_CONFIG } from "../configuration/dvm-configuration";
import { DVMConfiguration } from "../configuration/dvm-configuration.model";

@Injectable()
export class ClickIndoorPreviewHandler {
    constructor(protected dvmService: DVMService,
                @Inject(DVM_CONFIG) private dvmConfig: DVMConfiguration) {
        this.dvmService.viewer3dSubject.subscribe(
            viewer3d => {
                viewer3d.subscribe('click_indoor_preview', (obj) => {
                    // this.dvmService.load3DView(obj.indoor);
                    this.dvmService.viewer3d.loadView3d(obj.indoor);
                });
            }
        );

    }
}
