import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-common',
  template: '<ui-view></ui-view>',
})
export class AppCommonComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
