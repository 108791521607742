

import { InjectionToken } from "@angular/core";
import { DVMConfiguration } from "./dvm-configuration.model";

export const DVM_CONFIG = new InjectionToken<DVMConfiguration>('DVM Configuration');
export const DVMConfig: DVMConfiguration = {
    venue_id: "",
    map_id: "",
    container: "",
    version: ""
};
