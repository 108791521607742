import {UIRouter, RootModule} from '@uirouter/angular';
import { Injector, Inject } from '@angular/core';
import { ApplicationConfiguration } from '../app.configuration';

import { states } from './app-routing.states';
import { token } from '../globals';
import { AppConfiguration } from '../app-configuration.model';

export const config: RootModule = {
  states: states,
  otherwise: {state: getState(), params: {t: token}},
  initial: {state: getState(), params: {t: token}},
  useHash: false,
  config: uiRouterConfigFn
};

function getState() {
  switch (ApplicationConfiguration.applicationType) {
    case 'Relocation':
      return 'common.preview.landing';
      break;
    case 'NewSales':
      return 'common.seatselection.landing';
      break;
    default:
      return 'common.preview.landing';
      break;
  }
}

/** UIRouter Config placeholder  */
function uiRouterConfigFn(router: UIRouter, injector: Injector) { }
