import { FileParserCustomService } from './data-parsing/file-parser-custom.service';
import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { AngularFontAwesomeModule } from 'angular-font-awesome';
import { NgxPopperModule } from 'ngx-popper';


import { AppComponent } from './app.component';

import { APP_CONFIG, ApplicationConfiguration } from "./configuration/app-configuration";
import { DVM_CONFIG, DVMConfig } from "./configuration/dvm-configuration";


import { AppRoutingModule } from './app-routing/app-routing.module';
import { AppCommonComponent } from './app-common';
import { FileParserHelperService, SeatgeekConnectionModule, SEATGEEK_CONNECTION_CONFIG } from 'ngx-seatgeek-connection';
import { NgxBootstrapSliderModule } from 'ngx-bootstrap-slider';

import { HeaderComponent } from './header/header.component';

import { Token, variables } from './variables';
import { ConfigurationService } from './services/configuration.service';
import { SeatGeekConfig, SEATGEEK_CONFIG } from './configuration/seatgeek-configuration';
import { SeatGeekConfiguration } from './configuration/seatgeek-configuration.model';
import { ModalsConfig, MODALS_CONFIG } from './configuration/modals-configuration';



@NgModule({
  declarations: [
    AppComponent,
    AppCommonComponent,
    HeaderComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AngularFontAwesomeModule,
    NgxPopperModule,
    NgxBootstrapSliderModule,
    SeatgeekConnectionModule.forRoot(SeatGeekConfig)
  ],
  providers: [
    {provide: APP_CONFIG, useValue: ApplicationConfiguration},
    {provide: DVM_CONFIG, useValue: DVMConfig},
    {provide: SEATGEEK_CONNECTION_CONFIG, useValue: SeatGeekConfig},
    {provide: MODALS_CONFIG, useValue: ModalsConfig},
    {provide: FileParserHelperService, useClass: FileParserCustomService}, // test with different file input format
    {provide: Token, useValue: variables},
    ConfigurationService,
    {provide: APP_INITIALIZER, useFactory: (configService: ConfigurationService) => 
      () => configService.init(), deps: [ConfigurationService], multi: true}
  ],
  bootstrap: [AppComponent]
})


export class AppModule { }
// export function configFactory(config: SeatGeekConfiguration, configService: ConfigurationService) {
//   return () => configService.init(config)
// }
