import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { SeatgeekConnectionConfiguration, SEATGEEK_CONNECTION_CONFIG } from "ngx-seatgeek-connection";
import { of } from "rxjs";
import { catchError } from "rxjs/operators";
import { APP_CONFIG } from "../configuration/app-configuration";
import { AppConfiguration } from "../configuration/app-configuration.model";
import { DVM_CONFIG } from "../configuration/dvm-configuration";
import { DVMConfiguration } from "../configuration/dvm-configuration.model";
import { SeatGeekConfig, SEATGEEK_CONFIG } from "../configuration/seatgeek-configuration";
import { PriceTypeService } from "../configuration/standard-services/price-type.service";
import * as configurationJson from "./../configuration/configuration.json";
import { FetcherService } from "./fetcher.service";

@Injectable({
  providedIn: "root",
})
export class ConfigurationService {
  constructor(
    private http: HttpClient,
    private priceTypeService: PriceTypeService,
    private fetcherService: FetcherService,
    @Inject(APP_CONFIG) private appConfig: AppConfiguration,
    @Inject(DVM_CONFIG) private dvmConfig: DVMConfiguration,
    @Inject(SEATGEEK_CONNECTION_CONFIG) private seatgeekConfig: SeatgeekConnectionConfiguration
  ) {}

  public init() {
    //console.log("***Client default price type", this.temp.defaultPriceType);
    // retrieve json from somewhere or have the json compiled and choose one based on url or any other logic
    // https://regex101.com/
    const regex = /^(?:http|https):\/\/(?:local\.)?(?:private\.)?(?:dev-)?(.*)\.3dseasontickets\.com.*/gm;
    const client = regex.exec(location.href)[1];

    if (client && typeof client === "string") {
      this.fetcherService.fetchData(client);
      return this.fetcherService.onDataFetched$.toPromise();
    }
  }
}
