import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-seatselection',
  templateUrl: './seatselection.component.html',
})
export class SeatSelectionComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
