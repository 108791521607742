import { Component, OnInit, Input, Inject } from '@angular/core';
import {SeatgeekConnectionSeriesService, SeatgeekConnectionIdtranslatorService, SeriesItem} from 'ngx-seatgeek-connection';
import { ModalsService } from '../services/modals.service';
declare var $: any;
import * as $ from 'jquery';
import * as globals from '../globals';
import { ColoringService } from '../services/coloring.service';
import { APP_CONFIG } from "../configuration/app-configuration";
import { AppConfiguration } from "../configuration/app-configuration.model";
import { DVM_CONFIG } from "../configuration/dvm-configuration";
import { DVMConfiguration } from "../configuration/dvm-configuration.model";
import { FlowControlService } from '../services/flow-control.service';
import { IsolatedSeatsService } from '../services/isolated-seats.service';
import { first } from 'rxjs/operators';
import { DVMService } from '../services/dvm.service';
import { createPopper } from '@popperjs/core';
import { Placement } from 'ngx-popper';
import { Popover3dviewService } from '../services/popover-3dview.service';
import { LoaderService } from '../services/loader.service';
import { PriceTypeService } from '../configuration/standard-services/price-type.service';
import { SeatSelectionService } from '../services/seat-selection.service';


@Component({
  selector: 'app-ticket',
  templateUrl: './ticket.component.html',
  styleUrls: ['./ticket.component.css']
})
export class TicketComponent implements OnInit {
    @Input() seat: SeriesItem;
    @Input() isSelectionAllowed: boolean;
    @Input() discardedSeats: Object;
    discarded = false;
    confPopover;
    contentPopover;
    tooltip: HTMLElement;
    relocatedPrice: number | undefined;
    descriptionSections = {
    };
    descriptionText = '';

    get isGeneralAdmission() {
        return this.modals.getGeneralAdmissionAreas()[this.seat.productData.area];
    }

    get isDiscarded() {
        return this.discarded;
    }

    seatPopoverContent(node, description?, price?) {
        const seating = node.id.split('-');
        const section = seating[0].split('_')[1].split('(')[0];
        const row = seating[1];
        const seat = seating[2];
        let tooltipContent = `<div class='ticket-selected-view'>
            <div class='ticket-selected-view-img'></div>
        </div>
        <ul class='list-unstyled ticket-selected'>
            <li>
                <span class='ticket-val'>Click to open view</span>
            </li>
        </ul>
        <ul class='flex ticket-selected-seat'>
        <li style='display:block;'>
                <span class='ticket-lab'>Section</span>
                <span class='ticket-val'>` + section + `</span>
            </li>
            <li>
                <span class='ticket-lab'>Row</span>
                <span class='ticket-val'>` + row + `</span>
            </li>
            <li>
                <span class='ticket-lab'>Seat</span>
                <span class='ticket-val'>` + seat + `</span>
            </li>
        </ul>`;
        if (price) {
            tooltipContent += `<ul class='flex ticket-selected-seat'>
            <li style='display:block;'>
                <span class='ticket-lab'>Price</span>
                <span class='ticket-val'>` + price + `</span>
            </li>
        </ul>`;
        }
        if (description) {
            tooltipContent += '<ul class=\'list-unstyled ticket-selected-seat\'>' +
            '<li style=\'display:block;\'>' +
            '<span class=\'ticket-description\'>' + description + '</span> ' +
            '</li>' +
            '</ul>';
        }
        return tooltipContent;
    }

    public mouseEnter(seat) {
        const obj = this.dvmService.viewer.getNodeById(seat.productData.mmcSeatId);
        // console.log(obj);
        if (document.querySelector('#tooltip')) {
            this.popover3dview.deleteTimer();
            if (obj && (obj.state === 'available' || obj.state === 'selected')) {
                const node = obj;
                this.popover3dview.currentView = node.id;
    
                // Receive tooltip HTML Element
                this.tooltip = document.querySelector('#tooltip');
                // console.log(this.tooltip);
    
                // Generate tooltip content based on node type
                let tooltipContent;
                let description;
                if (this.descriptionSections[node.id.split('-')[0]]) {
                    description = this.descriptionText;
                }
                let price;
                if (this.pricing.seatpricing[node.id]) {
                    price = this.pricing.seatpricing[node.id];
                }
                tooltipContent = this.seatPopoverContent(node, description, price);
                
    
                // Create content HTML Element, clean previous popover and append
                const tooltipContentHTML = document.createElement('div');
                tooltipContentHTML.innerHTML = tooltipContent.trim();
                const popoverBodyNode = document.querySelector('#tooltip .popover-body');
                while (popoverBodyNode.firstChild) {
                    popoverBodyNode.removeChild(popoverBodyNode.firstChild);
                }
                popoverBodyNode.appendChild(tooltipContentHTML);
    
                // Request and place thumbnail
                this.dvmService.viewer3d.getThumbnail({ venue_id: this.dvmService.viewer.getVenueId(),
                    view_id: node.id }, true).then((img) => {
                    $('.ticket-selected-view-img').html('');
                    $('.ticket-selected-view-img').css('background-image', 'url("' + img + '")');
                });
    
                // Calculate popover placement
                let placement: Placement;
                if (node.anchor.dom[1] > (document.getElementById('main-interface').offsetHeight / 2)) {
                    placement = 'top';
                } else {
                    placement = 'bottom';
                }
    
                // Initiate popover
                createPopper(node, this.tooltip, {
                    placement,
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [0, 8],
                            },
                        },
                        {
                            name: 'flip',
                            options: {
                                fallbackPlacements: [],
                            }
                        }
                    ],
                });
                // Display popover
                this.tooltip.setAttribute('data-show', '');
            }
        }
    }

    public mouseLeave(seat) {
        const tooltip: HTMLElement = document.querySelector('#tooltip');
        tooltip.removeAttribute('data-show');
    }

    public toggleDiscarded(seat) {
        this.mouseLeave(seat);
        this.discarded = !this.discarded;
        if (this.discarded) {
            this.discardedSeats[seat.id] = seat;
            this.flowControl.discardedSeats[seat.id] = seat;
            this.dvmService.viewer.unselect(seat.productData.mmcSeatId);
            console.log(this.flowControl.discardedSeats);
        } else {
            if (this.discardedSeats[seat.id]) {
                delete this.discardedSeats[seat.id];
                delete this.flowControl.discardedSeats[seat.id];
                this.dvmService.viewer.select(seat.productData.mmcSeatId);
            console.log(this.flowControl.discardedSeats);

            }
        }
    }

    public locateSeat(seat) {
        let promise = Promise.resolve(null);
        if (this.dvmService.viewer.getMapId() !== seat.productData.area) {
            const loadOptions = JSON.parse(JSON.stringify(this.dvmConfig));
            loadOptions.map_id = seat.productData.area;
            loadOptions.venue_id = this.dvmService.viewer.getVenueId();
            promise = this.dvmService.loadMap(loadOptions);
            // this.showTickets();
        }
        return promise;
    }

    public open3dView(view, areaView= false) {
        // console.log(view);
        this.dvmService.load3DView(view.productData.mmcSeatId);
    }

    public removeTicket(seat) {
        this.loaderService.addProcess("removeticket");
        // this.flowControl.showLoader(true);
        if (this.isGeneralAdmission) {
            this.connection.discardBasketItems(seat['id']).subscribe(
                discardBasketItemsResponse => {
                    // this.flowControl.showLoader(false);
                    this.loaderService.removeProcess("removeticket");

                },
                error => {
                    const errorMessage = error.message.split('%%%')[0];
                    const errorText = error.message.split('%%%')[1];
                    this.modals.throwErrorModal(errorMessage, errorText);
                    // this.flowControl.showLoader(false);
                    this.loaderService.removeProcess("removeticket");
                }
            );
            return;
        }
        const locateSeatPromise = this.locateSeat(seat).then( () => {
            if (seat['productData']['area'] !== this.dvmService.viewer.getMapId()) {
                this.flowControl.availabilityLoaded.pipe(first()).subscribe(
                    x => {
                        this.isolatedRemoveCheck(seat);
                    });
            } else {
                this.isolatedRemoveCheck(seat);
            }
        });
    }

    private isolatedRemoveCheck(seat) {
        let isSelected = false;
        const currentSeat = this.dvmService.viewer.getNodeById(seat['productData']['mmcSeatId']);
        if (currentSeat) {
            isSelected = (currentSeat.state === 'selected');
        }
        if (isSelected) {
            const seatObject = {'itemid': seat['id'], 'id': seat['productData']['mmcSeatId']};
            this.seatSelectionService.unselectseat(seatObject).subscribe(
                _ => {
                  console.log("Current basket despues de unselect", _)
                  this.loaderService.removeProcess("removeticket");
                },
                error => {
                  const [errorMessage, errorText] = error.message.split("%%%");
                  this.modals.throwErrorModal(errorMessage, errorText);
                  this.loaderService.removeProcess("removeticket");
                }
              );
        } else {
            // this.flowControl.showLoader(false);
            this.loaderService.removeProcess("removeticket");
        }
    }

    public showTickets() {
        if (globals.isMobile()) {
            if (document.getElementById('ticketsList').classList.contains('hidden')) {
                document.getElementById('ticketsList').classList.remove('hidden');
                document.getElementById('ticketsTitle').classList.remove('hidden');
                if (this.isSelectionAllowed) {
                    document.getElementById('newSeason').classList.remove('hidden');
                }
                $('#showTickets').find('.fa').removeClass('fa-angle-up');
                $('#showTickets').find('.fa').addClass('fa-angle-down');
            } else {
                document.getElementById('ticketsList').classList.add('hidden');
                document.getElementById('ticketsTitle').classList.add('hidden');
                if (this.isSelectionAllowed) {
                    document.getElementById('newSeason').classList.add('hidden');
                }
                document.getElementById('sub-interface').style.height = '38px !important';
                $('#showTickets').find('.fa').removeClass('fa-angle-down');
                $('#showTickets').find('.fa').addClass('fa-angle-up');
            }
        }
    }

    constructor(private connection: SeatgeekConnectionSeriesService,
        private translator: SeatgeekConnectionIdtranslatorService,
        private popover3dview: Popover3dviewService,
        private modals: ModalsService,
        private coloring: ColoringService,
        private flowControl: FlowControlService,
        private isolatedSeats: IsolatedSeatsService,
        private seatSelectionService: SeatSelectionService,
        private dvmService: DVMService,
        private pricing: PriceTypeService,
        private loaderService: LoaderService,
        @Inject(DVM_CONFIG) private dvmConfig: DVMConfiguration,
        @Inject(APP_CONFIG) public app_config: AppConfiguration) {
    }

    ngOnInit() {
        if (this.discardedSeats.constructor === Array) {
            // console.log(this.seat);
            
            const prevSeat = (this.discardedSeats as SeriesItem[]).find(discarded => discarded.SubstitutedForItemId === this.seat.id);
            // console.log(prevSeat)
            this.relocatedPrice = prevSeat && prevSeat.price;
        }
    }

    // tslint:disable-next-line:use-life-cycle-interface
    ngAfterViewInit() {
        this.coloring.applyTicketColors();
    }
}
