import { Injectable, Inject } from '@angular/core';
declare var $: any;
import * as $ from 'jquery';
import { APP_CONFIG } from "../configuration/app-configuration";
import { AppConfiguration } from "../configuration/app-configuration.model";

@Injectable({
    providedIn: 'root'
})

export class ColoringService {

    constructor(@Inject(APP_CONFIG) private app_config: AppConfiguration) {}

    public applyMapColors() {
        const appConfig = this.app_config;
        /* MAP ELEMENTS */
        $('.seat.available').css('fill', appConfig.colors.mapElement.available);
        $('.seat.selected').css('fill', appConfig.colors.mapElement.selected);
        $('.seat.pending').css('fill', appConfig.colors.mapElement.pending);
    }

    public applyInterfaceColors() {
        const appConfig = this.app_config;
        /* MODALS */
        $('.btn-modal-confirm').css('background', appConfig.colors.buttons);
        $('.ticket-lab').css('color', appConfig.colors.popoverText);
        /* BUTTON */
        $('.mapdata-button').mouseenter(function() {
            $(this).css('background-color', appConfig.colors.buttonsHover);
        }).mouseleave(function() {
            $(this).css('background-color', appConfig.colors.buttons);
        });
    }

    public applyTicketColors() {
        const appConfig = this.app_config;
        $('.icon-trash').mouseenter(function() {
            $(this).css('color', appConfig.colors.tickets.actionsHover);
        }).mouseleave(function() {
            $(this).css('color', appConfig.colors.tickets.actions);
        });
        $('.fa-map-marker').mouseenter(function() {
            $(this).css('color', appConfig.colors.tickets.actionsHover);
        }).mouseleave(function() {
            $(this).css('color', appConfig.colors.tickets.actions);
        });
        $('.icon-eye').mouseenter(function() {
            $(this).css('color', appConfig.colors.tickets.actionsHover);
        }).mouseleave(function() {
            $(this).css('color', appConfig.colors.tickets.actions);
        });
    }
}
