import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { SeatgeekConnectionSeriesService, SeriesItem } from 'ngx-seatgeek-connection';
import { AsyncSubject, forkJoin, of, Subject } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { APP_CONFIG } from '../app-configuration';
import { AppConfiguration } from '../app-configuration.model';

@Injectable({
  providedIn: 'root'
})
export class PriceTypeService {
  public seatpricingSeasonEmployee;
  public seatpricingCompEmployee;
  
  public seatpricing;
  public areapricing;

  public priceTypesBySeat;

  public hasMultiplePriceTypes = false;
  public multiplePriceTypes;

  public pricetypesList;
  public customPriceTypes;


  public overridePriceType;
  // public defaultPriceType; // Llenar desde el init si se quiere tener aqui


  constructor(private http: HttpClient,
    @Inject(APP_CONFIG) private appConfig: AppConfiguration) {
  }


  getPriceType(nodeId: string) {

    let priceType;
    
    if (this.hasMultiplePriceTypes && this.multiplePriceTypes[nodeId]) {
      priceType = null;
    } else if (this.customPriceTypes && this.customPriceTypes.seats[nodeId]) {
      priceType = this.customPriceTypes.seats[nodeId];
    } else if (this.customPriceTypes && this.customPriceTypes.sections[nodeId.split("-")[0]]) {
      priceType = this.customPriceTypes.sections[nodeId.split("-")[0]];
    } else if (this.overridePriceType) {
      priceType = this.overridePriceType;
    } else {
      priceType = this.appConfig.defaultPricetype;
      if (!priceType) {
        throw new Error("No pricetype available");
      }
    }
    return priceType;
  }

  setPriceType(priceType:SeriesItem['priceType']): void {
    
    if(priceType.name === "Season Employee Purchase"){
      this.seatpricing = this.seatpricingSeasonEmployee;
    }

    if(priceType.name === "Comp Employee"){
      this.seatpricing = this.seatpricingCompEmployee;

    }
    
    this.overridePriceType = priceType.name;
  }

  TitansGetPriceKey(priceName: string): string {

    if(priceName === "Season Employee Purchase"){
      return "e6d4ce02-6e61-ee11-8445-bc4e14c94833"
    }

    if(priceName === "Comp Employee"){
      return "a92d1579-6559-ee11-8445-d1b58d2036ee";
    }

  }
}
