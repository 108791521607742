import { Injectable, Inject } from '@angular/core';
import { DVMService } from '../services/dvm.service';
import { GeneralHandler } from './general.handler';
import { DVM_CONFIG } from "../configuration/dvm-configuration";
import { DVMConfiguration } from "../configuration/dvm-configuration.model";

@Injectable()
export class NavigationHandler {
    constructor(protected dvmService: DVMService,
                @Inject(DVM_CONFIG) private dvmConfig: DVMConfiguration) {
        this.dvmService.viewer3dSubject.subscribe(
            viewer3d => {
                viewer3d.subscribe('click_navigation_node', (obj) => {
                    this.dvmService.load3DView(obj.navigation.view_id);
                });
            }
        );

    }
}
