import { Injectable, Inject } from '@angular/core';
import { DVMService } from '../services/dvm.service';
import { GeneralHandler } from './general.handler';
import { DVM_CONFIG } from "../configuration/dvm-configuration";
import { DVMConfiguration } from "../configuration/dvm-configuration.model";
import { FlowControlService } from '../services/flow-control.service';
import { SeatgeekConnectionSeriesService, SeatgeekConnectionIdtranslatorService } from 'ngx-seatgeek-connection';
import { ModalsService } from '../services/modals.service';
import { LoaderService } from '../services/loader.service';
import { SeatgeekConnectionService } from '../services/seatgeek-connection.service';
// import { PricingService } from '../services/pricing.service';

@Injectable()
export class LoadSeatselectionHandler extends GeneralHandler {
    constructor(@Inject(DVM_CONFIG) private dvmConfig: DVMConfiguration,
                private flowControl: FlowControlService,
                private translator: SeatgeekConnectionIdtranslatorService,
                private modals: ModalsService,
                // private pricing: PricingService,
                private seatgeekConnectionService: SeatgeekConnectionService,
                private connection: SeatgeekConnectionSeriesService,
                protected dvmService: DVMService,
                private loaderService: LoaderService) {
        super('end_load', dvmService);
    }

    loadBlockmapHandler(viewer) {
        console.log('load blockmap handler');
        if (!this.loaderService.isLoadingApplication) {
            this.loaderService.addProcess("selectionblockmap");
        }
        // this.flowControl.showLoader(true);
        viewer.setAvailability([]);
        this.connection.getAreaAvailableSeatsCount().subscribe(
            responseCount => {
            },
            error => {
                console.log(error);
            }
        );
        this.seatgeekConnectionService.getGeneralAvailability().subscribe(
            generalAvailability => {
                if (generalAvailability != null) {
                    // console.log(viewer.setAvailable('section', viewer.getNodesByType('section')));
                    viewer.setAvailable('section', generalAvailability);
                    viewer.setAvailable('section', Object.keys(this.flowControl.suitesHash));
                    viewer.setNodesTag(Object.keys(this.flowControl.suitesHash), 'vip');
                    // status.blockmap.moduleRef['setElementUnavailable'](this.lockedAreas);
                }
                // this.flowControl.showLoader(false);
                if (!this.loaderService.isLoadingApplication) {
                    this.loaderService.removeProcess("selectionblockmap");
                } else {
                    this.loaderService.fadeIn();
                }

            },
            error => {
                console.error('error in getGeneralAvailability', error);
                this.modals.throwErrorModal(error.message);
                // this.flowControl.showLoader(false);
                if (!this.loaderService.isLoadingApplication) {
                    this.loaderService.removeProcess("selectionblockmap");
                } else {
                    this.loaderService.fadeIn();
                }

            }
        );

    }

    loadSeatmapHandler(viewer) {
        // this.flowControl.showLoader(true);
        this.loaderService.addProcess("selectionblockmap");
        this.loadMinimap(viewer.getMapId());
        viewer.setAvailability([]);
        this.translator.translationsHolder.subscribe(
            translationsHolderData => {
                const seatmapId = viewer.getMapId();
                const areaId = translationsHolderData['frommmc']['areas'][seatmapId]['seatgeekId'];
                this.connection.getAreaAvailability(areaId).subscribe(
                    areaAvailability => {
                        if (areaAvailability != null) {
                        viewer.setAvailable('seat', areaAvailability);
                        }
                        this.connection.Basket.subscribe(
                            currentBasket => {
                                // console.log("Executing connection basket subscription")
                                setTimeout(() => {
                                    const currentSeats = [];
                                    for (let i = 0; i < currentBasket.length; ++i) {
                                        if (currentBasket[i]['insertionMode'] !== 'Return') {
                                            currentSeats.push(currentBasket[i]['productData']['mmcSeatId']);
                                        }
                                    }
                                    // console.log(currentSeats);
                                    this.dvmService.viewer.setAvailable('seat', currentSeats);
                                    this.dvmService.viewer.unselectAll();
                                    this.dvmService.viewer.select(currentSeats);
                                    // this.coloring.applyMapColors();
                                });
                            },
                            error => {
                              return null;
                            }
                        );
                        // this.flowControl.showLoader(false);
                        this.loaderService.removeProcess("selectionblockmap");

                    }
                );
            },
            error => {
                console.error('Error loading ID translations.');
                this.modals.throwErrorModal(error.message);
                // this.flowControl.showLoader(false);
                this.loaderService.removeProcess("selectionblockmap");
            }
        );
    }

    loadMinimap(node) {
        this.dvmService.viewer_mini.unselectAll();
        this.dvmService.viewer_mini.select(node);
        this.dvmService.viewer_mini.goTo(node, this.dvmService.viewer_mini.min_scaling_factor * 2.5, 1000);
    }

    protected handle(error, viewer) {
        // console.log('load seatselection handle');
        if (error) { console.error(error); }
        viewer = viewer.instance;
        if (viewer.getMapId() === this.dvmConfig.map_id) {
            this.loadBlockmapHandler(viewer);
        } else {
            this.loadSeatmapHandler(viewer);
        }
    }
}
