import { Component, OnInit, Input, Inject } from '@angular/core';
import {SeatgeekConnectionSeriesService, SeatgeekConnectionIdtranslatorService} from 'ngx-seatgeek-connection';
declare var $: any;
import * as $ from 'jquery';
import * as globals from '../globals';
import { APP_CONFIG } from "../configuration/app-configuration";
import { AppConfiguration } from "../configuration/app-configuration.model";
import { FlowControlService } from '../services/flow-control.service';


@Component({
  selector: 'app-sub-interface',
  templateUrl: './sub-interface.component.html',
  styleUrls: ['./sub-interface.component.css']
})
export class SubInterfaceComponent implements OnInit {
    @Input() isSelectionAllowed: boolean;
    @Input() discardedSeats: Object;
    @Input() selectedSeats: Object[];
    sectionlab = 'SECTION';
    rowlab = 'ROW';
    seatlab = 'SEAT';
    pricelab = 'PRICE';
    subInterfaceTitle;

    get currentSeats() {
        let selectedSeatsByPT = [];
        selectedSeatsByPT[0] = [];
        selectedSeatsByPT[1] = [];
        
        if (this.isSelectionAllowed) {
            if (this.selectedSeats != null) {
                for (let i = 0; i < this.selectedSeats.length; ++i) {
                    
                    if (this.selectedSeats[i]['action'] === 'Update') {
                        selectedSeatsByPT[0].push(this.selectedSeats[i]);
                    } else {
                        selectedSeatsByPT[1].push(this.selectedSeats[i]);
                    }
                }
            }
        } else {
            selectedSeatsByPT = this.selectedSeats;
        }
        return selectedSeatsByPT;
    }

    get discardedSeatsCount() {
        if (this.discardedSeats != null) {
            return Object.keys(this.discardedSeats).length;
        } else {
            return 0;
        }
    }

    get selectedSeatsCount() {
        if (this.selectedSeats != null) {
            return this.selectedSeats.length;
        } else {
            return 0;
        }
    }

    public showTickets() {
        if (document.getElementById('ticketsList').classList.contains('hidden')) {
            document.getElementById('ticketsList').classList.remove('hidden');
            document.getElementById('ticketsTitle').classList.remove('hidden');
            if (this.isSelectionAllowed) {
                document.getElementById('addon').classList.remove('hidden');
                document.getElementById('newSeason').classList.remove('hidden');
            }
            $('#showTickets').find('.fa').removeClass('fa-angle-up');
            $('#showTickets').find('.fa').addClass('fa-angle-down');
        } else {
            document.getElementById('ticketsList').classList.add('hidden');
            document.getElementById('ticketsTitle').classList.add('hidden');
            if (this.isSelectionAllowed) {
                document.getElementById('addon').classList.add('hidden');
                document.getElementById('newSeason').classList.add('hidden');
            }
            document.getElementById('sub-interface').style.height = '38px !important';
            $('#showTickets').find('.fa').removeClass('fa-angle-down');
            $('#showTickets').find('.fa').addClass('fa-angle-up');
        }
    }

    public isMobile() {
        if ( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
            return true;
        } else {
            return false;
        }
    }

    constructor(private connection: SeatgeekConnectionSeriesService,
        private translator: SeatgeekConnectionIdtranslatorService,
        private flowControl: FlowControlService,
        @Inject(APP_CONFIG) public app_config: AppConfiguration) {
    }

    ngOnInit() {
        
        if (this.isMobile()) {
            document.getElementById('ticketsList').classList.add('hidden');
            document.getElementById('ticketsTitle').classList.add('hidden');
        }

        if (!this.isSelectionAllowed) {
            this.subInterfaceTitle = 'Your current seats';
        } else {
            this.subInterfaceTitle = 'Your seats';
        }
    }

    // tslint:disable-next-line:use-life-cycle-interface
    ngAfterViewChecked() {
        // this.state.resizeForce = true;
        // const ticketsListHeight = document.getElementById('main-interface').offsetHeight -
        // (<HTMLElement>document.getElementsByClassName('ticket-title')[0]).offsetHeight;
        // document.getElementById('ticketsList').style.height = ticketsListHeight + 'px';


        if (!globals.isMobile() && window.innerWidth >= 1200) {
            document.getElementById('sub-interface').style.height = (document.getElementById('main-interface').offsetHeight
            + document.getElementById('topbar').offsetHeight) + 'px';
        } else {
            document.getElementById('sub-interface').style.height = (window.innerHeight / 2
            - document.getElementById('bottom-interface').offsetHeight) + 'px';
        }


    }
}
