import { Inject, Injectable } from "@angular/core";
import { SeatgeekConnectionSeriesService, SeriesItem } from "ngx-seatgeek-connection";
import { iif, Observable, of } from "rxjs";
import { map, mergeMap, take, tap } from "rxjs/operators";
import { APP_CONFIG } from "../configuration/app-configuration";
import { AppConfiguration } from "../configuration/app-configuration.model";
import { LoaderService } from "./loader.service";
import { ModalsService } from "./modals.service";

// Interface to abstract and work with ngx-seatgeek-connection
@Injectable({
  providedIn: "root",
})
export class SeatgeekConnectionService {
  constructor(
    private connection: SeatgeekConnectionSeriesService,
    private loaderService: LoaderService,
    private modals: ModalsService,
    @Inject(APP_CONFIG) private appConfig: AppConfiguration
  ) {}

  get newSeatsCount$() {
    return this.connection.Basket.pipe(
      map((items) => items.filter((item) => item["insertionMode"] !== "Return" && item["action"] === "New").length),
      take(1)
    );
  }

  get addedSeatsCount$() {
    return this.connection.Basket.pipe(
      map((items) => items.filter((item) => item["insertionMode"] === "Add").length),
      take(1)
    );
  }

  getGeneralAvailability() {
    return this.connection.getGeneralAvailability().pipe(
      map((generalAvailability) => {
        const { whitelistSections: whitelist, blacklistSections: blacklist } = this.appConfig;
        // Sobreescritura para permitir que se apliquen los que existan
        //Aplicar whitelist si existe, filtrar los que si esten en la whitelist
        if (whitelist) {
          generalAvailability = generalAvailability.filter((sectionName) => whitelist.indexOf(sectionName) !== -1);
        }

        // Aplicar whitelist, quitar los que esten en la blacklist
        if (blacklist) {
          generalAvailability = generalAvailability.filter((sectionName) => blacklist.indexOf(sectionName) === -1);
        }
        // Retonar el valor final de generalAvailability (inicial si nada se ha aplicado)
        return generalAvailability;
      })
    );
  }

  get Basket() {
    return this.connection.Basket;
  }

  getBasketItems() {
    return this.connection.getBasketItems();
  }

  // Elimina los asientos realocados a un downgrade de precio, retorna basket los asientos que cumplieron
  private noDowngrades(items: SeriesItem[]) {
    // Final items observable, default to current items
    let finalItems$ = of(items);

    if (!this.appConfig.preventDowngrades) {
      return finalItems$;
    }

    // Items retornados para actualizar
    const updates = items.filter((item) => item.action === "Update" && item.insertionMode === "Return");

    // Si hay algun item para actualizar
    if (updates.length) {
      // Ids de los items que son mas baratos que el item que substituyen
      const toRemoveIds = updates
        .filter((returned) => {
          const substitute = items.find((item) => item.id === returned.SubstitutedForItemId);
          if (!substitute) {
            return false;
          } else {
            return substitute.price < returned.price;
          }
        })
        .map((returned) => Number(returned.SubstitutedForItemId));

      // Si hay alguna id de asiento que es downgrade
      if (toRemoveIds.length) {
        this.modals.noDowngradesModal();
        // Asignar los items de la cart restantes como items finales
        finalItems$ = this.discardBasketItems(toRemoveIds);
      }
    }

    return finalItems$;
  }

  addSubscriptionsToBasket(areaId, priceType, seatId): Observable<any> {
    seatId = seatId.map((id) => parseInt(id, 10));
    // console.log("Tenemos que prevenir los downgrades?", this.appConfig.preventDowngrades)
    return this.connection
      .addSubscriptionsToBasket(areaId, priceType, seatId)
      .pipe(mergeMap((items) => this.noDowngrades(items)));
  }

  // Interfaz para descartar, se puede añadir funcionalidad extra
  discardBasketItems(toRemoveIds) {
    return this.connection.discardBasketItems(toRemoveIds);
  }
}
