import { Component, OnInit, Input, Inject } from '@angular/core';
import { SeatgeekConnectionSeriesService, SeatgeekConnectionIdtranslatorService } from 'ngx-seatgeek-connection';
import { StateService } from '@uirouter/angular';
import { ModalsService } from '../services/modals.service';
declare var $: any;
import * as $ from 'jquery';
import * as globals from '../globals';
import { APP_CONFIG } from "../configuration/app-configuration"
import { AppConfiguration } from "../configuration/app-configuration.model"
import { ParkingService } from '../services/parking.service';
import { FlowControlService } from '../services/flow-control.service';
import { ModalsConfiguration } from '../configuration/modals-configuration.model';
import { MODALS_CONFIG } from '../configuration/modals-configuration';

@Component({
  selector: 'app-modals',
  templateUrl: './modals.component.html',
  styleUrls: ['./modals.component.css']
})
export class ModalsComponent implements OnInit {

  @Input() isSelectionAllowed;
  parkingGeneralAvailability;

  public parkingSlots = this.parking.parkingSlots;
  public selectedPricetype = this.config.defaultPricetype;

  constructor( 	private connection: SeatgeekConnectionSeriesService,
                private modals: ModalsService,
                private flowControl: FlowControlService,
                private translator: SeatgeekConnectionIdtranslatorService,
                private stateService: StateService,
                public parking: ParkingService,
                @Inject(APP_CONFIG) private config: AppConfiguration,
                @Inject(MODALS_CONFIG) public modalsConfig: ModalsConfiguration) { }

  private generalAdmissionSlots = 1;

  private checkout() {
    if (this.config.parkingConfiguration.isParkingAvailable) {
      $('#parkingCheckoutModal').modal('toggle');
      if ($('#parkingSlotSelect').find('option:selected').text() === 'Yes') {
        const parkingLevelId = $('#parkingSlotLevel').find('option:selected').val();
        const parkingSlotsNumber = $('#parkingSlotSlot').find('option:selected').val();
        this.parking.addParking(parkingLevelId, parkingSlotsNumber);
      } else {
          this.checkoutPostMessage();
      }
    } else {
      this.checkoutPostMessage();
    }
  }

  private addGASubscriptionsToBasket() {
    this.flowControl.showLoader(true);
    $('#generalAdmissionModal').modal('toggle');
    this.translator.translationsHolder.subscribe(
      translationsHolderData => {
          const areaId = translationsHolderData.frommmc['areas'][this.modals.currentGeneralAdmissionArea].seatgeekId;
          this.connection.getPriceTypes().subscribe(
          priceTypes => {
            let priceType;
            priceTypes.forEach(element => {
              if (element['b:value'] === this.config.defaultPricetype) {
                priceType = element['b:key'];
              }
            });
            if (!priceType) {
              this.modals.throwErrorModal('', 'The Price Type ' + this.config.defaultPricetype + ' was not found.');
              this.flowControl.showLoader(false);
              return;
            }
            let count = this.generalAdmissionSlots;
            for (let i = 0; i < this.generalAdmissionSlots; ++i) {
              this.connection.addGASubscriptionsToBasket(areaId, priceType).subscribe(
                addGASubscriptionsResponse => {
                  console.log(addGASubscriptionsResponse);
                  count--;
                  if (count === 0) {
                    this.flowControl.showLoader(false);
                  }
                },
                error => {
                  console.log(error);
                  console.log('error in addGASubscriptionsToBasket.');
                  this.modals.throwErrorModal(error.message, null);
                  this.flowControl.showLoader(false);
                }
              );
            }
          }
        );
      },
      error => {
        console.log('error in translationsHolderData.');
        this.modals.throwErrorModal(error.message, null);
        this.flowControl.showLoader(false);
      }
    );
  }

  private checkoutPostMessage() {
    window.parent.postMessage(JSON.stringify({EventName: 'checkout'}), '*');
    console.log('Checkout');
  }

  public APIerrorModal(error: any) {
    // ADD IN SERVICE
    $('.yourselection').text('OOPS!');
    $('.APIerrorTxt').text(error);
    $('#APIerror').modal();
  }

  public redirectTo3DMap() {
    window.parent.location.replace(this.config.redirect.redirectURL);
  }

  public justAddons() {
    let basket;
    let count;
    $('#justAddonsModal').modal('toggle');
    this.connection.Basket.subscribe(
       basketResponse => {
         basket = basketResponse;
         count = basket.length;
       },
       error => {
         console.log(error);
       }
    );
    for (let i = 0; i < basket.length; ++i) {
         this.connection.discardBasketItems(basket[i].id).subscribe(
           discardBasketItemsResponse => {
             count--;
             if (count <= 0) {
              this.stateService.go('common.seatselection.landing');
            }
           },
           error => {
             console.log(error);
           }
         );
     }
  }

  ngOnInit() {
    // TEST DE PARKING GENERAL AVAILABILITY
    if (this.config.parkingConfiguration.isParkingAvailable) {
      this.connection.getParkingGeneralAvailability(this.config).subscribe(
          parkingAvailability => {
              this.parkingGeneralAvailability = parkingAvailability;
          },
          error => {
              console.log(error);
          }
      );
    }
  }

}
