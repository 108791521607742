import * as $ from 'jquery';

export class Globals {

}

export function getQueryVariable(variable) {
    let query; let vars; let pair;
    query = window.location.search.substring(1);
    vars = query.split('&');
    for (let i = 0; i < vars.length; i++) {
        pair = vars[i].split('=');
        if (pair[0] === variable) {
            return pair[1];
        }
    }
    return(false);
}

export const token = getQueryVariable('t');

export const basketInDom = [];

export function isMobile() {
    if ( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
        return true;
    } else {
        return false;
    }
}

export function getMinZoom(mapcontainer_id) {
    const container = $('#' + mapcontainer_id);
    const bg = container.find('.svg-img-background');
    let svg_ar;

    if (bg.length > 0) {
        svg_ar = bg[0].width.baseVal.value / bg[0].height.baseVal.value;
    } else {
        return 1;
    }

    const con_ar = container.width() / container.height();

    return svg_ar / con_ar;
}
