import { AfterViewInit, Component, Input, OnInit, Inject } from '@angular/core';
import { StateService, Transition, TransitionService } from '@uirouter/angular';
import { SeatgeekConnectionIdtranslatorService, SeatgeekConnectionSeriesService } from 'ngx-seatgeek-connection';
import { Test, Token } from 'src/app/variables';
import { ColoringService } from 'src/app/services/coloring.service';
declare var $: any;
import * as $ from 'jquery';
import { FlowControlService } from 'src/app/services/flow-control.service';
import { ModalsService } from 'src/app/services/modals.service';
import { ClickSeatselectionHandler } from 'src/app/handlers/click-seatselection.handler';
import { LoadSeatselectionHandler } from 'src/app/handlers/load-seatselection.handler';
import { PopoverCreateHandler } from 'src/app/handlers/popover-create.handler';
import { PopoverDestroyHandler } from 'src/app/handlers/popover-destroy.handler';
import { NavigationHandler } from 'src/app/handlers/navigation.handler';
import { DVMService } from 'src/app/services/dvm.service';
import { ClickIndoorPreviewHandler } from 'src/app/handlers/click-indoor-preview.handler';
import { of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { PriceTypeService } from 'src/app/configuration/standard-services/price-type.service';
import { SeatSelectionService } from 'src/app/services/seat-selection.service';

@Component({
  selector: 'app-seatselection-landing',
  templateUrl: './seatselection-landing.component.html',
  styleUrls: ['./seatselection-landing.component.css'],
  providers: [
    ClickSeatselectionHandler,
    LoadSeatselectionHandler,
    PopoverCreateHandler,
    PopoverDestroyHandler,
    NavigationHandler,
    ClickIndoorPreviewHandler
  ]
})
export class SeatSelectionLandingComponent implements OnInit, AfterViewInit {
  _data;
  _selectedSeats: Object[];
  _priceTypes;

  public subscriptionCurrentBasket = this.connection.Basket.subscribe(
      currentBasket => {

        this._selectedSeats = [];
        if (currentBasket != null) {
          this.flowControl.relocationSeatsCount = 0;

          for (let i = 0; i < currentBasket.length; ++i) {
            if (currentBasket[i]['insertionMode'] !== 'Return') {
              this._selectedSeats.push(currentBasket[i]);

              if (this.dvmService.viewer) {
                this.dvmService.viewer.setAvailable('seat', currentBasket[i]['productData']['mmcSeatId']);
                this.dvmService.viewer.select(currentBasket[i]['productData']['mmcSeatId']);
                this.flowControl.relocationSeatsCount++;
              }
              
            } else {
              this.flowControl.relocationSeatsCount--;
              
            }
          }

        }

        // console.log("🚀 ~ file: seatselection-landing.component.ts ~ line 38 ~ SeatSelectionLandingComponent ~ currentBasket", currentBasket)
        // console.log("🚀 ~ file: seatselection-landing.component.ts ~ line 40 ~ SeatSelectionLandingComponent ~ this._selectedSeats", this._selectedSeats)
        
      },
      error => {
          console.log(error);
          this._selectedSeats = [];
      }
  );

  @Input() set data(dataReceived) {
    if (dataReceived != null) {
      const dataArray = Object.values(dataReceived);
      this._data = dataArray;
      this.seatSelectionService.addAdditionalSeats(dataArray.length);
    } else {
      this._data = [];
    }
  }
  get selectedSeats() {
    return this._selectedSeats;
  }
  get discardedSeats () {
    return this._data;
  }
  isSelectionAllowed: boolean;

  get priceTypes() {
    return this._priceTypes;
  }

  // constructor(private translator: SeatgeekConnectionIdtranslatorService, private series: SeatgeekConnectionSeriesService,
  //             private state: StateService, private transition: Transition, private tk3d: Tk3dService) {
  // }

  constructor(private state: StateService,
              private dvmService: DVMService,
              private connection: SeatgeekConnectionSeriesService,
              private flowControl: FlowControlService,
              private modals: ModalsService,
              private clickSeatselectionHandler: ClickSeatselectionHandler,
              private loadSeatselectionHandler: LoadSeatselectionHandler,
              private popoverCreateHandler: PopoverCreateHandler,
              private popoverDestroyHandler: PopoverDestroyHandler,
              private navigationHandler: NavigationHandler,
              private clickIndoorPreviewHandler: ClickIndoorPreviewHandler,
              private priceTypeService: PriceTypeService,
              private seatSelectionService: SeatSelectionService,
              private coloring: ColoringService) {
  }

  ngAfterViewInit() {
    this.coloring.applyInterfaceColors();
  }

  ngOnInit() {
    this.isSelectionAllowed = this.state.$current.data.isSelectionAllowed;


    this.connection.getSubscriptionData().pipe(
      mergeMap((subscriptionData) => {
        let action$ = null;
        if (subscriptionData) {
          const {pricetypes, productDescription} = subscriptionData;
          const seriesId = productDescription["em:Id"];
          this.flowControl.seriesId = seriesId;
          
          if (productDescription["em:Name"]["tf:GSEntry"]["tf:Text"]) {
            this.flowControl.seriesName = productDescription["em:Name"]["tf:GSEntry"]["tf:Text"];
          }
          
          // if (this.priceTypeService.customPriceTypes) {
          //   this.priceTypeService.overridePriceType = this.priceTypeService.customPriceTypes[seriesId];
          // }
          
          const firstDiscardedSeat: any = Object.values(this.flowControl.discardedSeats)[0];
          // this.priceTypeService.setPriceType(firstDiscardedSeat.priceType);
          this.priceTypeService.pricetypesList = pricetypes;

          action$ = this.connection.getBasketItems();
        } else {
          action$ = of("redirect");
        }
        return action$;
      })
    ).subscribe(response => {
      
      if (response === "redirect") {
        $('#redirectModal').modal({backdrop: 'static', keyboard: false});
        return;
      }
      
    }, error => {
      this.modals.throwErrorModal(error.message);
    });
    
    this.dvmService.restartDVM();

    // this.series.queryTransaction().subscribe(
    //   data => console.log(data)
    // );
    // this.series.getBasketItems().subscribe(
    //   data => console.log(data)
    // );
    // this.series.getAreaAvailability('90ECDE19-F055-E711-8118-129DBE5738B9').subscribe(
    //   data => console.log(data)
    // );
    // this.series.validateTransaction().subscribe(
    //   data => console.log(data)
    // );
    // const subscriptions = {
    //   'areaid' : '90ECDE19-F055-E711-8118-129DBE5738B9',
    //   'priceType': '90ECDE19-F055-E711-8118-129DBE5738B9',
    //   'seats': [260, 261, 262]
    // };
    // this.series.addSubscriptionsToBasket(subscriptions.areaid, subscriptions.priceType, [260]).subscribe(
    //   data => console.log(data)
    // );
  }

}
