

import { InjectionToken } from "@angular/core";
import { ModalsConfiguration } from "./modals-configuration.model";

export const MODALS_CONFIG = new InjectionToken<ModalsConfiguration>('Modals Configuration');
export const ModalsConfig: ModalsConfiguration = {
    relocation: {
        infomodal: {
            header: "",
            body: ""
        },
        continue: {
            header: "",
            body: ""
        }
    },
    seatselection: {
        infomodal: {
            header: "",
            body: ""
        },
        "downgrade-prevention": {
            header: "",
            body: ""
        },
        "downgrade-error": {
            header: "",
            body: ""
        },
        checkout: {
            hasParking: false,
            "header-parking": "",
            header: "",
            body: ""
        }
    }
};
