import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RootModule, UIRouterModule } from '@uirouter/angular';

import { SeatSelectionLandingComponent } from './seatselection-landing';
import { SeatSelectionComponent } from './seatselection.component';
import { SeatgeekConnectionModule, SeatgeekConnectionConfiguration } from 'ngx-seatgeek-connection';
import { states, config } from './seatselection-routing';
import { SharedModule } from '../shared/shared.module';
// import { SeatgeekConfiguration } from '../app.configuration';
import { SeatGeekConfig } from '../configuration/seatgeek-configuration';


@NgModule({
  imports: [
    CommonModule,
    UIRouterModule,
    SharedModule,
    UIRouterModule.forChild(config),
    // SeatgeekConnectionModule.forRoot(SeatGeekConfig)
  ],
  declarations: [SeatSelectionLandingComponent, SeatSelectionComponent]
})
export class SeatSelectionModule { }
