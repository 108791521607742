import { InjectionToken } from '@angular/core';

export interface Test {
    id: string;
    language: string;
    viewtype: string;
    lock: [];
    url: string;
    exceptions: [];
}
export const Token = new InjectionToken <Test>('Seatgeek connection configuration');
export const variables: Test = {
    id: 'nam-us-00008-sounders', // Not being used
    language: 'language',
    viewtype: 'viewtype',
    lock: [],
    url: 'url',
    exceptions: []
};
