import { Injectable } from '@angular/core';

import { FileParserHelperService} from 'ngx-seatgeek-connection';
import { HttpClient } from '@angular/common/http';
import {
  FileParserAreadescInput
} from 'ngx-seatgeek-connection';
import { FileParserAreadescCustomFileInput } from './file-parser-areadesc-custom-file-input.model';

@Injectable({
  providedIn: 'root'
})
export class FileParserCustomService extends FileParserHelperService {

  protected prepareAreaDescription(data: FileParserAreadescCustomFileInput[]): FileParserAreadescInput [] {
    for (const area of data) {
      area.sector = 'S_' + area.sector; // fixes the difference between area ids
      if (area.sector === 'S_PITCH' || area.sector === 'S_ECS') { // this sector are bad respresented in area-desc.csv
        area.sector += '1';
      }
    }
    return data;

  }

  constructor(protected http: HttpClient) { super(http); }
}
