import { Injectable, Inject } from '@angular/core';
import { DVM_CONFIG } from "../configuration/dvm-configuration";
import { Observable, Subject } from 'rxjs';
import { IsolatedSeatsService } from './isolated-seats.service';
declare var DVM: any;

@Injectable({
    providedIn: 'root'
})

export class DVMService {

    viewer;
    viewer3d;
    viewer_mini;
    viewerSubject: Subject<any> = new Subject();
    viewer3dSubject: Subject<any> = new Subject();
    isViewerSubjectInitialized = false;
    isViewer3dSubjectInitialized = false;
    subscribedCallbacks = {};

    constructor(@Inject(DVM_CONFIG) private DVMConfig) {
        // this.initializeDVM('map_viewer', DVMConfig);
        this.viewerSubject.subscribe(
            viewer => {
                this.viewer = viewer;
                (window as any).viewer = viewer;
            }
        );
        console.log('DVM Constructor');
    }

    public restartDVM() {
        // tslint:disable-next-line: forin
        for (const event in this.subscribedCallbacks) {
            this.subscribedCallbacks[event].forEach(callback => {
                this.viewer.unsubscribe(event, callback);
            });
        }
        this.isViewerSubjectInitialized = false;
        this.initializeDVM(this.DVMConfig);
    }

    private initializeDVM(config) {
        // Map viewer instance
        DVM.loadModule('map_viewer', config)
            .then((viewer) => {
                this.viewer = viewer;
                this.loadMap(config);
                if (!this.isViewerSubjectInitialized) {
                    this.viewerSubject.next(this.viewer);
                    this.isViewerSubjectInitialized = true;
                }
                this.viewer.flags.automatic_selection = false;
                this.viewer.flags.fixed_aspect_ratio = false;
            })
            .catch((err) => {
                console.error(err);
            });

        // Viewer 3D instance
        const viewer3dConfig = JSON.parse(JSON.stringify(config));
        viewer3dConfig.container = 'viewer3d-container';
        viewer3dConfig.plugins = ['navigation', 'indoor'];
        DVM.loadModule('3d_viewer', viewer3dConfig)
            .then((viewer3d) => {
                this.viewer3d = viewer3d;
                if (!this.isViewer3dSubjectInitialized) {
                    this.viewer3dSubject.next(this.viewer3d);
                    this.isViewer3dSubjectInitialized = true;
                }
                this.viewer3d.flags.fixed_aspect_ratio = false;
            })
            .catch((err) => {
                console.error(err);
            });

        // Minimap Viewer instance
        const viewerMiniConfig = JSON.parse(JSON.stringify(config));
        console.log(config);
        viewerMiniConfig.container = config.minimap_container;
        viewerMiniConfig.map_id = config.minimap_id;
        DVM.loadModule('map_viewer', viewerMiniConfig)
            .then((viewer_mini) => {
                this.viewer_mini = viewer_mini;
                this.viewer_mini.loadMap(viewerMiniConfig).then(
                    (obj) => {
                        const styles =
                        [
                            // Level 0. sections are visible, seats are hidden (no styles needed).
                            {
                                // Level 0 styles for nodes with "section" type
                                section: {
                                    available: {
                                        normal: {
                                            none: {
                                                fillStyle: 'grey',
                                                strokeStyle: 'grey',
                                                fillOpacity: 0.7,
                                                lineWidth: 0.2
                                            }
                                        }
                                    },
                                    selected: {
                                        normal: {
                                            none: {
                                                fillStyle: 'white',
                                                strokeStyle: 'white',
                                                fillOpacity: 0.7,
                                                lineWidth: 0.2
                                            }
                                        }
                                    },
                                    unavailable: {
                                        normal: {
                                            none: {
                                                fillStyle: 'transparent',
                                                strokeStyle: 'transparent',
                                                cursor: 'auto'
                                            }
                                        }
                                    },
                                    disabled: 'unavailable'
                                }
                            }
                        ];
                        obj.instance.setStyles(styles);
                        obj.instance.setAvailability('section', obj.instance.getNodesByType('section'));
                    }
                );
                this.viewer_mini.flags.panning = false;
                this.viewer_mini.flags.zooming = false;
                this.viewer_mini.flags.max_zoom_on_first_limit = false;
                this.viewer_mini.scaling_factor = this.viewer_mini.min_scaling_factor;
            })
            .catch((err) => {
                console.error(err);
            });
    }

    changeMapConfiguration(venueId) {
        if (this.viewer) {
            const viewerConfig = JSON.parse(JSON.stringify(this.DVMConfig));
            viewerConfig.venue_id = venueId;
            viewerConfig.map_id = this.viewer.getMapId();
            this.loadMap(viewerConfig);
        }
    }

    subscribeHandler(event: 'click' | 'end_load' | 'enter' | 'leave', callback) {
        const viewerSubjectSubscribe = this.viewerSubject.subscribe(
            viewer => {
                this.viewer.subscribe(event, callback);
                if (!this.subscribedCallbacks[event]) {
                    this.subscribedCallbacks[event] = [];
                }
                this.subscribedCallbacks[event].push(callback);
                viewerSubjectSubscribe.unsubscribe();
            }
        );
    }

    public loadMap(loadOptions) {
        return this.viewer.loadMap(loadOptions).then(() => {
            this.applyStyles(this.viewer);
        });
    }

    public load3DView(nodeId) {
        const loadOptions = JSON.parse(JSON.stringify(this.DVMConfig));
        loadOptions.view_id = nodeId;
        loadOptions.venue_id = this.viewer.getVenueId();
        this.viewer3d.loadView3d(loadOptions);
    }

    applyStyles(viewer) {
        const styles = viewer.getStyles();
        if ( styles && styles[0] && styles[0].section ) {
            styles[0].section.available.normal.none.fillStyle = "#578be9";
            styles[0].section.available.normal.none.strokeStyle = "white";
            styles[0].section.available.normal.none['fill-opacity'] = "0.7";
            
            styles[0].section.available.hover.none.fillStyle = "white";
            styles[0].section.available.hover.none.strokeStyle = "white";
            styles[0].section.available.hover.none.lineWidth = "1.5";
    
            styles[0].section.selected.normal.none.fillStyle = "white";
            styles[0].section.selected.normal.none.strokeStyle = "white";
    
            styles[0].section.unavailable.normal.none.fillStyle = "rgba(12,35,64,0.28)";
            styles[0].section.unavailable.normal.none['stroke-opacity'] = "0";
    
            viewer.setStyles(styles);
        } else if ( styles && styles[0] && styles[0].seat ) {
            if (!styles[0].seat.available.normal.pending) {
                styles[0].seat.available.normal.pending = {};
            }
            if (!styles[0].seat.selected.normal.pending) {
                styles[0].seat.selected.normal.pending = {};
            }
            if (!styles[0].seat.selected.hover.pending) {
                styles[0].seat.selected.hover.pending = {};
            }
            styles[0].seat.available.normal.pending.fillStyle = "orange";
            styles[0].seat.available.normal.pending.strokeStyle = "orange";

            styles[0].seat.selected.hover.pending.fillStyle = "orange";
            styles[0].seat.selected.hover.pending.strokeStyle = "orange";
            styles[0].seat.selected.hover.pending.cursor = "pointer";
            
            styles[0].seat.selected.normal.pending.fillStyle = "orange";
            styles[0].seat.selected.normal.pending.strokeStyle = "orange";
    
            viewer.setStyles(styles);
        }
    }

    private getNodeId(obj) {
        return (obj.nodes.length > 0) ? obj.nodes[0].id : null;
    }

}
